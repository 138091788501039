<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="name"
            >
              <b-form-input
                v-model.trim="myObj.name"
                placeholder="Enter menu name"
                @focusout="CheckName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Redirect To"
              invalid-feedback="Redirect To is required."
              ref="redirect"
            >
              <v-select
                v-model="myObj.actionType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="actionOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="select"
                @input="checkAction()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="myObj.actionType == 'page'">
            <b-form-group
              label="Page"
              invalid-feedback="Page is required."
              ref="page"
            >
              <v-select
                v-model="myObj.page"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                placeholder="select page"
                @input="checkPage()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="myObj.actionType == 'url'">
            <b-form-group
              label="URL"
              invalid-feedback="URL is required."
              ref="url"
            >
              <b-form-input
                v-model.trim="myObj.url"
                placeholder="Enter url"
                @focusout="CheckUrl()"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="Add()"
              block
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-menu"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      :title="sidebarTitle"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="name"
            >
              <b-form-input
                v-model.trim="myObj.name"
                placeholder="Enter menu name"
                @focusout="CheckName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Redirect To"
              invalid-feedback="Redirect To is required."
              ref="redirect"
            >
              <v-select
                v-model="myObj.actionType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="actionOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="select"
                @input="checkAction()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="myObj.actionType == 'page'">
            <b-form-group
              label="Page"
              invalid-feedback="Page is required."
              ref="page"
            >
              <v-select
                v-model="myObj.pageID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                label="title"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="select page"
                @input="checkPage()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="myObj.actionType == 'url'">
            <b-form-group
              label="URL"
              invalid-feedback="URL is required."
              ref="url"
            >
              <b-form-input
                v-model.trim="myObj.url"
                placeholder="Enter url"
                @focusout="CheckUrl()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="myObj.actionType == 'blogs'">
            <b-form-group
              label="Blogs"
              invalid-feedback="Blogs is required."
              ref="blogs"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="selectedBlogs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="blogOptions"
                label="title"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="select blogs"
              />
            </b-form-group>
          </b-col>
          <template v-if="myObj.actionType != 'blogs'">
            <b-col md="12" class="d-flex mb-50">
              <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                >Is Mega Menu?</label
              >
              <b-form-checkbox
                v-model="myObj.isMega"
                switch
                class="mr-0 ml-1"
              />
            </b-col>
            <b-col md="12" v-if="myObj.isMega">
              <b-form-group
                label=""
                invalid-feedback="Mega Menu is required."
                ref="mega"
              >
                <v-select
                  v-model="myObj.megaID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="megaOptions"
                  label="name"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="select mega menu"
                />
              </b-form-group>
            </b-col>
          </template>
          <b-col md="12" class="text-right">
            <b-button variant="success" @click="Add()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-logo"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      size="sm"
      title="Header Logo"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col>
            <b-form-group
              ref="image"
              label="Image"
              invalid-feedback="Image is required."
            >
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="logoObj.logo"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>

          <b-col md="12" class="text-right mt-1">
            <b-button
              variant="success"
              @click="saveLogo()"
              :disabled="saving || logoloading == true"
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-card no-body>
      <b-row class="p-2">
        <b-col>
          <b-button @click="OpenAddModal(0)" variant="primary" class="mr-1">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Add Menu</span>
          </b-button>
          <b-button @click="openLogo()" variant="primary" :disabled="loading2">
            <span class="text-nowrap">Header Logo</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :items="items"
        :fields="fields"
        show-empty
        responsive
        :busy="dataLoading"
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(actionType)="data">
          {{
            data.value == "page"
              ? getPage(data.item.pageID)
              : data.value == "url"
              ? data.item.url
              : data.value
          }}
        </template>

        <template #cell(actions)="data">
          <template>
            <b-button
              v-if="data.item.status == 'active'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="mr-1 btn-icon"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.placement.left
              title="Inactive"
              size="16"
              @click="ChangeStatus(data.item, 'inactive')"
            >
              <feather-icon size="16" icon="XIcon" />
            </b-button>

            <b-button
              v-else
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              class="mr-1 btn-icon"
              v-b-tooltip.hover.v-success
              v-b-tooltip.placement.left
              title="Active"
              size="16"
              @click="ChangeStatus(data.item, 'active')"
            >
              <feather-icon size="16" icon="CheckIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 btn-icon"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Edit"
              @click="OpenAddModal(data.item)"
            >
              <feather-icon size="16" icon="EditIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.placement.right
              title="Delete"
              size="16"
              @click="DeleteFlavour(data.item.id)"
            >
              <feather-icon size="16" icon="TrashIcon" />
            </b-button>
          </template>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BSpinner,
    BFormCheckbox,
    BSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      dataLoading: false,
      fields: [
        "#",
        { label: "name", key: "name" },
        { label: "url / page", key: "actionType" },
        "actions",
      ],
      items: [
        // {
        //   id: 1,
        //   name: "About us",
        //   actionType: "page",
        //   page: "",
        //   pageID: 2,
        //   url: "",
        // },
      ],
      request: false,
      myObj: {
        id: 0,
        name: "",
        actionType: "",
        page: "",
        url: "",
      },
      visibility: false,
      sidebarTitle: "",
      actionOptions: [
        { text: "None", value: "none" },
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
        { text: "Blogs", value: "blogs" },
      ],
      pageOptions: [
        // { id: 1, title: "Home" },
        // { id: 2, title: "About" },
        // { id: 3, title: "Projects" },
        // { id: 4, title: "Contact" },
      ],
      logoData: [],
      logoObj: {
        id: 0,
        logo: "",
        type: "header",
      },
      saving: false,
      logoloading: false,
      loading2: false,
      megaOptions: [],
      selectedBlogs: [],
      blogOptions: [],
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadPages();
      this.LoadLogos();
      this.LoadMega();
      this.LoadBlogs();
    }
  },
  methods: {
    openLogo() {
      let obj = this.logoData.find((el) => el.type == "header");
      // console.log(obj);
      if (obj) {
        this.logoloading = "loaded";
        this.logoObj = { ...obj };
      } else {
        this.logoloading = false;
        this.logoObj = {
          id: 0,
          logo: "",
          type: "header",
        };
      }
      this.$bvModal.show("modal-logo");
    },
    selectlogo() {
      let obj = this.$refs.fileProfile.files[0];

      if (obj !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            var fn = e.data.myresp[0].path;

            this.logoObj.logo = fn;
            this.logoloading = "loaded";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      this.logoObj.logo = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    saveLogo() {
      if (this.logoObj.logo == "") {
        return this.$bvToast.toast("Please upload the logo", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.saving = true;
        if (this.logoObj.id == 0) {
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/Logos",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.logoObj,
          };

          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadLogos();
                this.$bvToast.toast("Logo saved successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.$bvModal.hide("modal-logo");
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        } else {
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Logos/" + this.logoObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.logoObj,
          };
          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadLogos();
                this.$bvToast.toast("Logo saved successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.$bvModal.hide("modal-logo");
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        }
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAction() {
      var elem = this.$refs["redirect"];
      if (this.myObj.actionType == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPage() {
      if (this.myObj.actionType == "page") {
        var elem = this.$refs["page"];
        if (this.myObj.pageID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckUrl() {
      if (this.myObj.actionType == "url") {
        var elem = this.$refs["url"];
        if (this.myObj.url == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckBlog() {
      if (this.myObj.actionType == "blogs") {
        var elem = this.$refs["blogs"];
        if (this.selectedBlogs.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckMega() {
      if (this.myObj.actionType != "blogs" && this.myObj.isMega) {
        var elem = this.$refs["mega"];
        if (this.myObj.megaID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    getPage(id) {
      let obj = this.pageOptions.find((el) => el.id == id);
      return obj ? obj.title : "";
    },
    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          name: "",
          actionType: "",
          page: "",
          url: "",
          status: "active",
          pageID: 0,
          isMega: false,
          megaID: 0,
        };
        this.selectedBlogs = [];
        this.sidebarTitle = "Add Menu";
      } else {
        // blogIDs are saved in page column because column page was unused
        this.myObj = { ...id };
        this.selectedBlogs = this.myObj.page
          ? this.myObj.page.split(",").map((el) => parseInt(el))
          : [];
        this.sidebarTitle = "Edit Menu";
      }
      // this.visibility = true;
      this.$bvModal.show("modal-menu");
      // console.log(this.selectedBlogs);
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Menu/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire("Success!", "Menu has been deleted.", "success").then(
                  (res) => {
                    this.LoadData();
                  }
                );
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    ChangeStatus(item, val) {
      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${val} the menu?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          item.status = val;
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Menu/" + item.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: item,
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                this.LoadData();
                this.$bvToast.toast("Menu is " + item.status + " now!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    Add() {
      this.CheckName();
      this.checkAction();
      this.checkPage();
      this.CheckUrl();
      this.CheckMega();
      this.CheckBlog();
      if (
        this.CheckName() == false ||
        this.checkAction() == false ||
        this.checkPage() == false ||
        this.CheckUrl() == false ||
        this.CheckMega() == false ||
        this.CheckBlog() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.myObj);
        this.request = true;
        if (this.myObj.actionType == "blogs") {
          this.myObj.page = this.selectedBlogs.join(",");
          this.myObj.isMega = false;
          this.myObj.megaID = 0;
        }
        if (this.myObj.id == 0) {
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/Menu",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              this.request = false;
              if (response.data.status === "success") {
                // console.log(response.data);
                Swal.fire("Success!", "Menu added successfully.", "success");
                this.LoadData();
                this.$bvModal.hide("modal-menu");
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.request = false;
              console.log(error);
            });
        } else {
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Menu/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              this.request = false;
              if (response.data.status === "success") {
                // console.log(response.data);
                this.$bvModal.hide("modal-menu");
                this.LoadData();

                this.$bvToast.toast("Menu updated successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.request = false;
              console.log(error);
            });
        }
      }
    },
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Menu",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.items = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadPages() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.pageOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadLogos() {
      this.loading2 = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Logos",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.logoData = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.loading2 = false));
    },
    LoadMega() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/MegaMenu",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.megaOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadBlogs() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Blogs",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.blogOptions = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
